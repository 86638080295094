import mapImage from '_assets/img/map.jpg';

const Contact = () => {
    return (
        <section className="section_map section-bg-2" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h2 className="ui-title-block">Contact us</h2>
                        <div className="ui-subtitle-block_mod-b">We service across the north shore from Artarmon to Castle Hill and as far north as Hornsby.</div>
                    </div>
                </div>
            </div>

            <div className="map"><img className="img-responsive" src={mapImage} height="520" width="1600" alt="map" /></div>

            <div className="container text-center">

                <div className="row">
                    <div className="col-xs-12">
                        <div className="decor-2"><i className="icon fa fa-caret-down"></i></div>
                        <h2 className="ui-title-inner">feel free to send us a message <br />or ask for a FREE quote</h2>
                        <div className="decor-1 center-block"></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-10 col-sm-offset-1">
                        <form className="form-contact" action="post">
                            <div className="row">
                                <div className="col-sm-6">
                                    <input className="form-control" type="text" placeholder="Name" required />
                                </div>
                                <div className="col-sm-6">
                                <input className="form-control" type="text" placeholder="Phone No." required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <textarea className="form-control" placeholder="Message Details ..." required rows="7"></textarea>
                                    <div className="btn">
                                        <div className="wrap__btn-skew-r">
                                            <button className="btn-skew-r btn-effect "><span className="btn-skew-r__inner">send message</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Contact;