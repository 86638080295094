const WindowTinting = () => {
    return (
        <div className="section_default section_mod-e section-bg-2" id="window-tinting">
            
            <section className="section_mod-t">
                <div className="container">

                    <div className="row">
                        <div className="col-xs-12">
                            <h2 className="ui-title-block">Window Tinting</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5">
                            <div className="decor-1 decor-1_mod-c"></div>
                            <div className="ui-description"><p>Unique Tinting has been in business for more than 20 years and during this time, has tinted more than 25,000 luxury cars, family cars, executive cars, run-abouts, lemons and even bombs. The preferred tinting supplier to Lexus of Chatswood, Mercedes Benz, Lamborghini, Fiat, Alfa Romeo, Mazda and Hyundai; our customer service and skills are second to none.</p></div>
                            <p>Car window tinting is one of the best ways to improve the look, efficiency and security of your vehicle. Window tinting adds style and elegance to the appearance and has numerous other benefits - outlined below.</p>
                            <p>In the event of a broken window, tinting film protects the occupants of the vehicle from flying glass by holding shattered glass in place. All our tints provide you with this protection. Furthermore, all our tints offer 99% UV light rejection.</p>
                            <p>Among the products we install are security films, which will hold up against accidental breakage and vandalism. Furthermore, because thieves tend to break in to vehicles through the side windows, darker tints can provide added security by obscuring a thief's view. Not only that, many of our films are scratchresistant.</p>
                            <p>Depending on your selected tint, up to 80% of the sun's total energy can be reflected away from your vehicle. If your vehicle is fitted with air-conditioning this should provide savings on your fuel and running costs. Tinting will also reduce interior fading and help maintain the value of your vehicle.</p>
                            <p>Films applied by Unique Tinting increase driving comfort, reduce glare, remove heat gain and protect from ultraviolet rays. Unique Tinting stocks a full range of high performance automotive films, manufactured by 3M, Suntek, DTI, PPG and other leading brands.</p>
                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default WindowTinting