import brand1 from '_assets/media/brands/brand-1.jpg';
import brand2 from '_assets/media/brands/brand-2.jpg';
import brand3 from '_assets/media/brands/brand-3.jpg';
import brand4 from '_assets/media/brands/brand-4.jpg';
import brand5 from '_assets/media/brands/brand-5.jpg';

const Services = () => {
    return (
        <div className="wrap-section-border">
            <section className="section_mod-b section-bg section-bg_primary">
                <div className="bg-inner border-section-top border-section-top_mod-a">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <h2 className="ui-title-block ui-title-block_mod-a text-center">Our Brands</h2>
                                <div className="ui-subtitle-block_mod-a">some of the brands we work with</div>
                                
                                    <ul className="list-brands list-inline">
                                        <li className="list-brands__item">
                                            <div className="list-brands__inner"><img className="list-brands__img img-responsive" src={brand1} height="57" width="250" alt="3M" /></div>
                                        </li>
                                        <li className="list-brands__item">
                                            <div className="list-brands__inner"><img className="list-brands__img img-responsive" src={brand2} height="57" width="250" alt="3M" /></div>
                                        </li>
                                        <li className="list-brands__item">
                                            <div className="list-brands__inner"><img className="list-brands__img img-responsive" src={brand3} height="57" width="250" alt="GTechniq" /></div>
                                        </li>
                                        <li className="list-brands__item">
                                            <div className="list-brands__inner"><img className="list-brands__img img-responsive" src={brand4} height="57" width="250" alt="Permanon" /></div>
                                        </li>
                                        <li className="list-brands__item">
                                            <div className="list-brands__inner"><img className="list-brands__img img-responsive" src={brand5} height="57" width="250" alt="WFAANZ" /></div>
                                        </li>
                                    </ul>
        
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;