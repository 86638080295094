import slider01 from '_assets/media/slider-gallery/01.png';
import slider02 from '_assets/media/slider-gallery/02.png';
import slider03 from '_assets/media/slider-gallery/03.png';
import slider04 from '_assets/media/slider-gallery/04.png';
import slider05 from '_assets/media/slider-gallery/05.png';
import slider06 from '_assets/media/slider-gallery/06.png';
import slider07 from '_assets/media/slider-gallery/07.png';
import slider08 from '_assets/media/slider-gallery/08.png';
import slider09 from '_assets/media/slider-gallery/09.png';
import slider10 from '_assets/media/slider-gallery/10.png';

const Gallery = () => {
    return (
        <div className="wrap-section-border" id="gallery">
            <section className="section_mod-f section-bg section-bg_second">
                <div className="bg-inner border-section-top border-section-top_mod-b">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h2 className="ui-title-block ui-title-block_mod-a text-center">latest galleries</h2>
                                <div className="ui-subtitle-block_mod-a">checkout the awesome images & videos</div>
                                <div className="slider-gallery owl-carousel owl-theme owl-theme_mod-a enable-owl-carousel" data-min480="2" data-min768="3" data-min992="4" data-min1200="4" data-pagination="true" data-navigation="false" data-stop-on-hover="true">

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider01} rel="prettyPhoto"> <img className="img-responsive" src={slider01} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider02} rel="prettyPhoto"> <img className="img-responsive" src={slider02} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider03} rel="prettyPhoto"> <img className="img-responsive" src={slider03} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider04} rel="prettyPhoto"> <img className="img-responsive" src={slider04} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider05} rel="prettyPhoto"> <img className="img-responsive" src={slider05} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider06} rel="prettyPhoto"> <img className="img-responsive" src={slider06} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider07} rel="prettyPhoto"> <img className="img-responsive" src={slider07} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider08} rel="prettyPhoto"> <img className="img-responsive" src={slider08} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider09} rel="prettyPhoto"> <img className="img-responsive" src={slider09} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                    <div className="slider-gallery__item"> <a className="slider-gallery__link" href={slider10} rel="prettyPhoto"> <img className="img-responsive" src={slider10} height="177" width="280" alt="" />
                                        <div className="slider-gallery__hover"> <i className="icon icon-magnifier-add"></i></div>
                                    </a> </div>

                                </div>
                            </div>
                        </div>

                        <div className="border-section-bottom"></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Gallery;