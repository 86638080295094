const Testimonials = () => {
    return (
        <section className="section_mod-g section-bg section-bg_primary">
            <div className="bg-inner border-section-top border-section-top_mod-a">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h2 className="ui-title-block ui-title-block_mod-a text-center">what our customers say</h2>
                            <div className="slider-reviews owl-carousel owl-theme owl-theme_mod-a enable-owl-carousel" data-min480="1" data-min768="2" data-min992="3" data-min1200="3" data-pagination="true" data-navigation="false" data-auto-play="400000" data-stop-on-hover="true">

                                <div className="reviews">
                                    <h3 className="reviews__title">Thank you Greg!</h3>
                                    <div className="decor-1"></div>
                                    <div className="reviews__text">
                                        <p>Just wanted to let you know how great you guys have been. I also wanted to say thanks for helping us out when we called you at the last minute & requested a tint to be done that afternoon or the following morning. You always just say "no problem" & get it done. Thank you Greg & we look forward to working with you in the near future.</p>
                                    </div>
                                    <div className="reviews__inner">
                                        <div className="reviews__name">Service Manager</div>
                                        <div className="reviews__category">McCarrolls Alfa & Fiat</div>
                                    </div>
                                </div>

                                <div className="reviews">
                                    <h3 className="reviews__title">Customer Satisfaction</h3>
                                    <div className="decor-1"></div>
                                    <div className="reviews__text">
                                        <p>I have been using Greg's company for the past 9 years and have always received professional, friendly, and courteous service since the first day we started doing business. Lexus customers have high expectations, and Greg has exceeded these expectations on every occasion, providing the best quality product available in the market at competitive prices. Last minute requests were accommodated, and Unique Tinting always put out customer's satisfaction at the forefront. I would not hesitate in recommending Greg to anyone and I personally look forward to many more years of association with him and Unique Tinting.</p>
                                    </div>
                                    <div className="reviews__inner">
                                        <div className="reviews__name">General Sales Manager</div>
                                        <div className="reviews__category">Lexus of Chatswood</div>
                                    </div>
                                </div>

                                <div className="reviews">
                                    <h3 className="reviews__title">Professional & Punctual</h3>
                                    <div className="decor-1"></div>
                                    <div className="reviews__text">
                                        <p>I have known Greg professionally for more than 6 years. During this time, Greg has applyed premium window tint and full protection packs to our vehicles. Throughout this time we have found Greg's work to be second to none. His team is always professional, punctual and we have no hesitation in recommending Unique Window Tinting to any prospective customers.</p>
                                    </div>
                                    <div className="reviews__inner">
                                        <div className="reviews__name">Sales Manager</div>
                                        <div className="reviews__category">McCarrolls Hyundai</div>
                                    </div>
                                </div>

                                <div className="reviews">
                                    <h3 className="reviews__title">Attention to Detail</h3>
                                    <div className="decor-1"></div>
                                    <div className="reviews__text">
                                        <p>Unique Window Tinting recently applied window tinting and surface protection treatments to our fleet of company vehicles. We found their attention to detail, customer service and product knowledge to be second to none. Not only do our cars now look superb - the treatments applied by Unique have contributed to the efficient and economical running of our vehicles. The treatments applied by Unique have all but replaced the need to detail the cars. It is without hesitation that we recommend Unique's products and services.</p>
                                    </div>
                                    <div className="reviews__inner">
                                        <div className="reviews__name">Director</div>
                                        <div className="reviews__category">Start Grow Run</div>
                                    </div>
                                </div>

                                <div className="reviews">
                                    <h3 className="reviews__title">Terrific Tinting Job</h3>
                                    <div className="decor-1"></div>
                                    <div className="reviews__text">
                                        <p>I just wanted to thank you for the terrific tinting job you did on my new car. Michael, the chap who did the tint was a pleasure to have at my house, he did a great job and cleaned up all his off cuts, and took them away. You should be very proud of the business you have built, it must be something to do with your management.</p>
                                    </div>
                                    <div className="reviews__inner">
                                        <div className="reviews__name">Stephen</div>
                                        {/* <div className="reviews__category">Start Grow Run</div> */}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="border-section-bottom"></div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;