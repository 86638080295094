import Header from '_components/Header';
import Footer from '_components/Footer';

export { Contact };

function Contact() {
    return (
        <>
            <Header />

            <div className="block-title">
                <div className="block-title__inner section-bg section-bg_second">
                    <div className="bg-inner">
                        <h1 className="ui-title-page">Contact Us</h1>
                        <div className="decor-1 center-block"></div>
                        <ol className="breadcrumb">
                            <li><a href="/">HOME</a></li>
                            <li className="active">Contact</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <main className="main-content">

                            <form className="comment-reply-form" id="comment-reply-form" action="#" method="get">
                                <h3 className="ui-title-inner">FEEL FREE TO SEND US A MESSAGE OR ASK FOR A FREE QUOTE</h3>
                                <div className="decor-1"></div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <input className="form-control" type="text" id="comment-author" name="author" placeholder="Full Name" required />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="form-group">
                                            <input className="form-control" type="email" id="comment-email" name="email" placeholder="Email Address" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <textarea className="form-control" id="comment-text" name="comment" rows="5" placeholder="Your Message" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="wrap__btn-skew-r">
                                            <button className="btn-skew-r btn-skew-r_mod-a btn-effect "><span className="btn-skew-r__inner">send message</span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </main>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}