import uniqueLogo from '_assets/img/unique-logo-colour.png';

const Header = () => {
    return (
        <div className="header">

            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="header-contacts"> <span className="header-contacts__item"><i className="icon fa fa-phone"></i>0413 103 576</span> <a className="header-contacts__item" href="mailto:greg.seggie@uniquetinting.com.au"><i className="icon fa fa-envelope"></i>greg.seggie (at) uniquetinting.com.au</a> </div>
                            <ul className="social-links list-inline">
                                <li><a className="icon fa fa-facebook" href="https://www.facebook.com/uniquewindowtinting"></a></li>
                                <li><a className="icon fa fa-instagram" href="https://www.instagram.com/uniquewindowtinting"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header__inner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12"> <a href="/" className="logo"> <img className="logo__img img-responsive" src={uniqueLogo} width="350" alt="Unique Window Tinting" /> </a>
                            <div className="navbar yamm">
                                <div className="navbar-header hidden-md hidden-lg hidden-sm">
                                    <button type="button" data-toggle="collapse" data-target="#navbar-collapse-1" className="navbar-toggle"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                                    <a href="javascript:void(0);" className="navbar-brand"></a>
                                </div>
                                <div id="navbar-collapse-1" className="navbar-collapse collapse">
                                    <ul className="nav navbar-nav">
                                        <li><a href="/">HOME</a></li>
                                        <li><a href="/window-tinting">WINDOW TINTING</a></li>
                                        <li><a href="/paint-protection">PAINT PROTECTION</a></li>
                                        <li><a href="/gallery">GALLERY</a></li>
                                        <li><a href="/contact">CONTACT</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;