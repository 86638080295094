import link1 from '_assets/media/link-img/1.png';
import link2 from '_assets/media/link-img/2.png';
import link3 from '_assets/media/link-img/3.png';
import link4 from '_assets/media/link-img/4.png';
import uniqueLogo from '_assets/img/unique-logo-dark.png';

const About = () => {
    return (
        <section className="section_mod-a section-bg-2">
            <div className="bg-inner">
                <div className="container">

                    <div className="row">
                        <div className="col-xs-12">
                            <div className="main-block text-center">
                                <div className="main-block__label">welcome to</div>
                                <div className="decor-1 center-block"></div>
                                <div className="main-block__title"><img src={uniqueLogo} width="250" alt="Unique Window Tinting" /></div>
                                <div className="decor-2"><i className="icon fa fa-caret-down"></i></div>
                            </div>
                            <div className="wrap-link-img">
                                <ul className="link-img link-img_mod-a list-inline wow bounceInLeft" data-wow-duration="2s">
                                    <li className="link-img__item">
                                        <div className="link-img__link">
                                            <img className="img-responsive" src={link1} height="250" width="170" alt="Automotive Tinting" />
                                            <div className="link-img__wrap-title"><span className="link-img__title">Automotive</span></div>
                                        </div>
                                    </li>
                                    <li className="link-img__item">
                                        <div className="link-img__link">
                                            <img className="img-responsive" src={link2} height="250" width="170" alt="Marine Tinting" />
                                            <div className="link-img__wrap-title"><span className="link-img__title">Marine</span></div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="link-img link-img_mod-b list-inline wow bounceInRight" data-wow-duration="2s">
                                    <li className="link-img__item">
                                        <div className="link-img__link">
                                            <img className="img-responsive" src={link3} height="250" width="170" alt="Aviation Tinting" />
                                            <div className="link-img__wrap-title"><span className="link-img__title">Aviation</span></div>
                                        </div>
                                    </li>
                                    <li className="link-img__item">
                                        <div className="link-img__link">
                                            <img className="img-responsive" src={link4} height="250" width="170" alt="Commercial Tinting" />
                                            <div className="link-img__wrap-title"><span className="link-img__title">Commercial</span></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;