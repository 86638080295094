import About from '_components/About';
import Contact from '_components/Contact';
import Footer from '_components/Footer';
import Header from '_components/Header';
import Slider from '_components/Slider';
import Testimonials from '_components/Testimonials';
import Services from '_components/Services';
import Gallery from '_components/Gallery';
import WindowTinting from '_components/WindowTinting';

export { Home };

function Home() {
    return (
        <>
            <Header />
            <Slider />
            <About />
            <Services />

            <div className="section_default section_mod-e section-bg-2">
                <section className="section_mod-d">
                    <div className="container">

                        <div className="row">
                            <div className="col-xs-12">
                                <h2 className="ui-title-block">About Unique Tinting</h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-5">
                                <h3 className="ui-title-inner">Trusted Name in Tinting and Surface Protection</h3>
                                <div className="decor-1 decor-1_mod-c"></div>
                                <div className="ui-description"><p>Unique Tinting has been tinting cars, homes, boats, and planes for more than 20+ years.</p></div>
                                <p>With a stellar reputation and second-to-none policy on customer service. Unique Tinting provides customers with cost-effective solutions at affordable prices.</p>
                                <p>A full-service tinting company, our experienced, professional tinting team will come to you - anywhere, anytime. At no additional cost, our specialists will tint your car, house, office, boat or plane while you wait (or while you finish the shopping or take that much needed golf lesson).</p>
                                <p><i>Why use Unique Tinting?</i> Whether you're tinting your car, house, office, boat or plane, it's vital that you select the correct film and the right team for the job. A Unique Tinting specialist is trained to assess more than a dozen different variables and to apply more than 60 different films.</p>
                                <p>Factors such as colour, visibility, security, light reduction and energy savings are critical to identifying the correct film and with an average of more than 20+ years experience, the Unique Tinting team has the expertise to identify and apply the most suitable film for your surface.</p>
                                <p>Unique Tinting also provide the latest in paint, fabric, wheel and leather protection. With minimal disruption, our internationally acclaimed protection products provide a new lease of life to worn, faded or damaged surfaces including car wheels, car paint, furniture and upholstery.</p>
                            </div>
                        </div>

                    </div>
                </section>

            </div>

            <Gallery />

            <WindowTinting />

            <div className="wrap-section-border">
                <Testimonials />
            </div>

            <Contact />
            <Footer />
        </>
    )
}