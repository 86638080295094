import Header from '_components/Header';
import Footer from '_components/Footer';

import imageHeader from '_assets/media/window-tinting.jpg';

export { WindowTinting };

function WindowTinting() {
    return (
        <>
            <Header />

            <div className="block-title">
                <div className="block-title__inner section-bg section-bg_second">
                    <div className="bg-inner">
                        <h1 className="ui-title-page">Window Tinting</h1>
                        <div className="decor-1 center-block"></div>
                        <ol className="breadcrumb">
                            <li><a href="/">HOME</a></li>
                            <li className="active">Window Tinting</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <main className="main-content">

                            <article class="post post_mod-b post_mod-d clearfix">
                                <div class="entry-media">
                                    <img class="img-responsive" src={imageHeader} width="1140" alt="Window Tinting"/>
                                </div>

                                <div class="entry-main entry-main_mod-a">
                                    <div class="decor-1"></div>
                                    <div class="entry-content">
                                        <p><strong>Unique Tinting has been in business for more than 20 years and during this time, has tinted more than 25,000 luxury cars, family cars, executive cars, run-abouts, lemons and even bombs. The preferred tinting supplier to Lexus of Chatswood, Mercedes Benz, Lamborghini, Fiat, Alfa Romeo, Mazda and Hyundai; our customer service and skills are second to none.</strong></p>
                                        <p>Car window tinting is one of the best ways to improve the look, efficiency and security of your vehicle. Window tinting adds style and elegance to the appearance and has numerous other benefits - outlined below.</p>
                                        <p>In the event of a broken window, tinting film protects the occupants of the vehicle from flying glass by holding shattered glass in place. All our tints provide you with this protection. Furthermore, all our tints offer 99% UV light rejection.</p>
                                        <p>Among the products we install are security films, which will hold up against accidental breakage and vandalism. Furthermore, because thieves tend to break in to vehicles through the side windows, darker tints can provide added security by obscuring a thief's view. Not only that, many of our films are scratchresistant.</p>
                                        <p>Depending on your selected tint, up to 80% of the sun's total energy can be reflected away from your vehicle. If your vehicle is fitted with air-conditioning this should provide savings on your fuel and running costs. Tinting will also reduce interior fading and help maintain the value of your vehicle.</p>
                                        <p>Films applied by Unique Tinting increase driving comfort, reduce glare, remove heat gain and protect from ultraviolet rays. Unique Tinting stocks a full range of high performance automotive films, manufactured by 3M, Suntek, DTI, PPG and other leading brands.</p>
                                    </div>
                                    <footer class="entry-footer">
                                        <div class="wrap-post-btn"><a class="post-btn btn-effect" href="/contact"><span class="post-btn__inner">Lets Talk</span></a></div>
                                        <div class="wrap-social-block wrap-social-block_mod-c">
                                            <div class="social-block ">
                                                <div class="social-block__inner">
                                                    <span class="social-block__title">SHARE THIS</span>
                                                    <ul class="social-block__list list-inline">
                                                        <li><a class="icon fa fa-facebook" href="javascript:void(0);"></a></li>
                                                        <li><a class="icon fa fa-twitter" href="javascript:void(0);"></a></li>
                                                        <li><a class="icon fa fa-google-plus" href="javascript:void(0);"></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </article>

                        </main>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}