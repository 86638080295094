import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Home } from "./home";
import { WindowTinting } from "window-tinting";
import { PaintProtection } from "paint-protection";
import { Gallery } from "gallery";
import { Contact } from "contact";

export { App };

function App() {
    return (
        <Router>

            <div id="this-top" className="layout-theme animated-css" data-header="sticky" data-header-top="200">

                <div id="wrapper">

                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/window-tinting" component={WindowTinting} />
                        <Route path="/paint-protection" component={PaintProtection} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/contact" component={Contact} />
                    </Switch>

                </div>

            </div>
            <span className="scroll-top"> <i className="fa fa-angle-up"> </i></span>

        </Router>
    );
}