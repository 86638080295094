import slider1 from '_assets/media/main-slider/sl21.jpg';
import slider2 from '_assets/media/main-slider/slgte.jpg';

const Slider = () => {
    return (
        <div id="sliderpro1" className="slider-pro main-slider">
        
            <div className="sp-slides"> 

                <div className="sp-slide">
                    <img className="sp-image" src={slider1} data-src={slider1} data-retina={slider1} alt="img" />
                    <div className="item-wrap sp-layer sp-padding" data-horizontal="100" data-vertical="300" data-show-transition="left" data-hide-transition="up" data-show-delay="400" data-hide-delay="200">
                        <div className="main-slider__inner text-left">
                            <div className="custom-caption">
                                <a className="main-slider__btn btn-effect" href="javascript:void(0);"><span className="main-slider__btn_inner">Black Armor Films</span></a>
                                <div className="main-slider__title">Revolutionary Window Films</div>
                                <div className="main-slider__subtitle">Designed and developed for Australia's harsh weather conditions</div>
                                <div className="decor-1"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sp-slide">
                    <img className="sp-image" src={slider2} data-src={slider2} data-retina={slider2} alt="img" />
                    <div className="item-wrap sp-layer sp-padding" data-horizontal="100" data-vertical="300" data-show-transition="left" data-hide-transition="up" data-show-delay="400" data-hide-delay="200">
                        <div className="main-slider__inner text-left">
                            <div className="custom-caption">
                                <a className="main-slider__alt_btn btn-effect" href="javascript:void(0);"><span className="main-slider__btn_inner">GTechniq</span></a>
                                <div className="main-slider__title">Australia's superior ceramic coatings</div>
                                <div className="main-slider__subtitle">Smart surface science</div>
                                <div className="decor-1"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Slider;