import Header from '_components/Header';
import Footer from '_components/Footer';

import imageHeader from '_assets/media/paint-protection.jpg';

export { PaintProtection };

function PaintProtection() {
    return (
        <>
            <Header />

            <div className="block-title">
                <div className="block-title__inner section-bg section-bg_second">
                    <div className="bg-inner">
                        <h1 className="ui-title-page">Paint Protection</h1>
                        <div className="decor-1 center-block"></div>
                        <ol className="breadcrumb">
                            <li><a href="/">HOME</a></li>
                            <li className="active">Paint Protection</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <main className="main-content">

                            <article class="post post_mod-b post_mod-d clearfix">
                                <div class="entry-media">
                                    <img class="img-responsive" src={imageHeader} width="1140" alt="Window Tinting"/>
                                </div>

                                <div class="entry-main entry-main_mod-a">
                                    <div class="decor-1"></div>
                                    <div class="entry-content">
                                        <p><strong>Protecting your investment against the rigors of day-to-day life is guaranteed with GTechniq and Permanon paint protection and glass coating. GTechniq and Permanon provide the latest in nano technology.</strong></p>
                                        <p>Paint protection and glass coating is essential to any car. Car paint can be damaged by all the elements, particularly the sun and sea. The sun will fade paint over time and the ocean’s salt water will harm the finish. Further, hail, dirt and rocks will destroy your car's exterior and everyday cleaning can scratch your car’s paint. Wax can certainly keep a car shiny, but it will also allow dirt particles to stick to it. This means that when you wipe it down, the dirt or sand will cause damage to the paint job (as wiping the car will grind the dirt or sand into the paint).</p>
                                        <p>For these reasons and more, it's important to get a good car paint protection system. Unique Tinting is known in the market for providing the latest in paint protection and glass coating. Protecting your vehicles from minor damage, Unique Tinting’s GTechniq and Permanon products offer the look of new paint with minimal disruption.</p>
                                        <p>GTechniq and Permanon has been technologically enhanced with smart surface functionality, which removes the age-old problem associated with current generation polish and protect systems (that is, a traditional polish only provides short term results).</p>
                                        <p>Permanon is a safe and simple-to-apply surface protection that produces brilliant shine on all solid surfaces. When applied, nano-engineered particles of Permanon Silicium (Si14) electrostatically bond and fill microscopic pores and pits that naturally occur in solid materials. Permanon bridges and fills these pores and pits; making it hard for dirt and bacteria to bond or embed to the surface. This process makes cleaning easier and more effective! Permanon is also resistant from -40 degrees Celsius to 300 degrees Celsius.</p>
                                        <p>The industry’s leader, GTechniq and Permanon paint protection and glass coating provides the best gloss and colour stability, is stain resistant and self-cleaning, scuff resistant and affective in the long-term. For more information on GTechniq and Permanon paint protection, Contact Us today.</p>
                                    </div>
                                    <footer class="entry-footer">
                                        <div class="wrap-post-btn"><a class="post-btn btn-effect" href="/contact"><span class="post-btn__inner">Lets Talk</span></a></div>
                                        <div class="wrap-social-block wrap-social-block_mod-c">
                                            <div class="social-block ">
                                                <div class="social-block__inner">
                                                    <span class="social-block__title">SHARE THIS</span>
                                                    <ul class="social-block__list list-inline">
                                                        <li><a class="icon fa fa-facebook" href="javascript:void(0);"></a></li>
                                                        <li><a class="icon fa fa-twitter" href="javascript:void(0);"></a></li>
                                                        <li><a class="icon fa fa-google-plus" href="javascript:void(0);"></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </article>

                        </main>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}