import footerLogo from '_assets/img/unique-logo-light.png';
import { HeartIcon } from '@heroicons/react/24/solid';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="wrap-section-border">
                <section className="section_mod-h section-bg section-bg_second">
                    <div className="bg-inner border-section-top border-section-top_mod-b">
                        <div className="container">

                            <div className="row">
                                <div className="col-xs-12">
                                    <img src={footerLogo} width="200" alt="Unique Window Tinting" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="footer__text">We service across the North Shore from Artarmon to Castle Hill and as far north as Hornsby.</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="footer__item"> <span className="footer__name">Phone:</span> <span className="footer__text">0413 103 576</span> </div>
                                    <div className="footer__item"> <span className="footer__name">email:</span> <span className="footer__text">greg.seggie@uniquetinting.com.au</span> </div>
                                    <div className="footer__item"> <span className="footer__name">Hours:</span> <span className="footer__text">Mon - Fri :: 8am - 4:30pm</span> </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <ul className="social-links list-inline">
                                        <li><a className="icon fa fa-facebook" href="https://www.facebook.com/uniquewindowtinting"></a></li>
                                        <li><a className="icon fa fa-instagram" href="https://www.instagram.com/uniquewindowtinting"></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

            <div className="footer__wrap-btn"> <a className="footer__btn scroll" href="#this-top">top</a> </div>
            <div className="copyright">
                Built with{' '}
                <HeartIcon
                    width={10}
                    height={10}
                    className="heart-icon"
                />
                {' '}
                in Sydney, Australia by <a className="copyright__link" href="https://saundersdigital.com.au">Saunders Digital</a> :: &copy; {new Date().getFullYear()} <a className='copyright__link' href="https://uniquetinting.com.au">Unique Window Tinting</a>
            </div>
        </footer>
    )
}

export default Footer;